import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles';

export default function Unavailability() {
  const classes = useStyles();
  const loadedDefault = 0;
  const [loaded, setLoaded] = useState(loadedDefault);
  const { company } = useSelector(state => state.data);

  useEffect(() => {
    if (company.name) {
      setLoaded(1);
    }
  }, [company]);

  return loaded === 1 &&
    company.somenteagendamento === 'N' &&
    company.enabled !== 1 ? (
    <div className={classes.root}>
      <span className={classes.text}>restaurante fechado</span>
    </div>
  ) : null;
}
