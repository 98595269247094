import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { addMinutes, format } from 'date-fns';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { orderScheduling } from '../../../store/modules/order/actions';

function Scheduling() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dates, setDates] = useState([]);
  const [hours, setHours] = useState([]);
  const [dateFormatted, setDateFormatted] = useState('');
  const [hourFormatted, setHourFormatted] = useState('');
  const [minutesFormatted, setMinutesFormatted] = useState('');

  const { company, appointments } = useSelector(state => state.data);
  const { scheduling } = useSelector(state => state.order.order);

  const handleDateClick = useCallback(
    date => {
      setDateFormatted(date.dateFormatted);
      setHourFormatted('');
      setMinutesFormatted('');

      dispatch(
        orderScheduling({
          scheduling: true,
          day: date.dateFormatted,
          hour: '',
          minutes: '',
        })
      );
    },
    [dispatch]
  );

  const handleHourClick = hour => {
    setHourFormatted(hour.hourFormatted);
    setMinutesFormatted(hour.minutesFormatted);

    dispatch(
      orderScheduling({
        scheduling: true,
        day: dateFormatted,
        hour: hour.hourFormatted,
        minutes: hour.minutesFormatted,
      })
    );
  };

  const handleChange = event => {
    const { checked } = event.target;

    if (checked) {
      handleDateClick(dates[0]);
    } else {
      dispatch(
        orderScheduling({
          scheduling: false,
          day: '',
          hour: '',
          minutes: '',
        })
      );

      setDateFormatted('');
      setHourFormatted('');
      setMinutesFormatted('');
    }
  };

  useEffect(() => {
    const moment = new Date();
    const dates = [];
    for (const appointment of appointments) {
      if (appointment.date === format(moment, 'yyyy-MM-dd')) {
        let show = false;
        for (const schedule of appointment.schedules) {
          const { time, before } = schedule;
          const [hour, minutes] = time.split(':');
          const h = parseInt(`${hour}${minutes}`);
          const now = parseInt(format(addMinutes(moment, before), 'HHmm'));
          if (h >= now) {
            show = true;
            break;
          }
        }
        if (show) {
          const [day, month] = appointment.date.split('-').reverse();
          dates.push({
            dateFormatted: appointment.date,
            dateFormattedDisplay: `${day}/${month}`,
          });
        }
      } else {
        const [day, month] = appointment.date.split('-').reverse();
        dates.push({
          dateFormatted: appointment.date,
          dateFormattedDisplay: `${day}/${month}`,
        });
      }
    }

    setDates(dates);
    if (company.somenteagendamento === 'S') {
      handleDateClick(dates[0]);
    }
  }, [company, appointments, handleDateClick]);

  useEffect(() => {
    if (dateFormatted.length !== 0) {
      const moment = new Date();
      const hours = [];
      const appointment = appointments.find(
        item => item.date === dateFormatted
      );
      if (appointment) {
        const { schedules } = appointment;
        for (const schedule of schedules) {
          const { time, show, before } = schedule;
          const [hour, minutes] = time.split(':');
          if (dateFormatted === format(moment, 'yyyy-MM-dd')) {
            const h = parseInt(`${hour}${minutes}`);
            const now = parseInt(format(addMinutes(moment, before), 'HHmm'));
            if (h >= now) {
              hours.push({
                hourFormatted: hour,
                hourFormattedDisplay: show,
                minutesFormatted: minutes,
              });
            }
          } else {
            hours.push({
              hourFormatted: hour,
              hourFormattedDisplay: show,
              minutesFormatted: minutes,
            });
          }
        }
      }
      setHours(hours);
    }
  }, [dateFormatted, appointments]);

  useEffect(() => {
    if (dateFormatted.length !== 0) {
      const button = document.querySelector(`#button-${dateFormatted}`);

      if (button) {
        button.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [dateFormatted]);

  useEffect(() => {
    if (hourFormatted.length !== 0) {
      const button = document.querySelector(
        `#button-${hourFormatted}-${minutesFormatted}`
      );

      if (button) {
        button.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [hourFormatted, minutesFormatted]);

  return company && company.aceitaragendamento === 'S' ? (
    <div className={classes.root}>
      {company.somenteagendamento === 'S' ? (
        <span className={classes.title}>Agende seu pedido</span>
      ) : (
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox onChange={handleChange} color="primary" />}
            label="Gostaria de agendar o pedido?"
          />
        </FormGroup>
      )}

      {scheduling && (
        <div className={classes.scheduling}>
          <div className={classes.content}>
            <span>Dia</span>
            <div className={classes.list}>
              {dates.map((date, index) => (
                <button
                  type="button"
                  id={`button-${date.dateFormatted}`}
                  key={`button`.concat(index)}
                  className={clsx(classes.button, {
                    [classes.buttonSelected]:
                      date.dateFormatted === dateFormatted,
                  })}
                  onClick={() => handleDateClick(date)}
                >
                  {`${date.dateFormattedDisplay}`}
                </button>
              ))}
            </div>
          </div>

          {hours.length >= 1 && (
            <div className={classes.content}>
              <span>Hora</span>
              <div className={classes.list}>
                {hours.map((hour, index) => (
                  <button
                    type="button"
                    id={`button-${hour.hourFormatted}-${hour.minutesFormatted}`}
                    key={`button`.concat(index)}
                    className={clsx(classes.button, {
                      [classes.buttonSelected]:
                        hour.hourFormatted === hourFormatted &&
                        hour.minutesFormatted === minutesFormatted,
                    })}
                    onClick={() => handleHourClick(hour)}
                  >
                    {`${hour.hourFormattedDisplay}`}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
}

export default Scheduling;
